<template>
  <div>
    <!-- <div class="row mb-4">
      <div class="col-md-2">
        <label>Select custom range</label>
        <flat-pickr
            v-model="selectedDates"
            class="form-control"
            :config="{ mode: 'range' }"
            name="date"
            :style="{ backgroundColor: '#FFF' }"
            @on-change="handleDateChanged"
          ></flat-pickr>
      </div>
      <div class="col-md-2">
        <label>Select predefined range</label>
        <select name="range" class="form-control" @change="selectRange">
          <option value="">Select range</option>
          <option value="last 3 days">Last 3 days</option>
          <option value="last 7 days">Last 7 days</option>
          <option value="this week">This week</option>
          <option value="last week">Last week</option>
          <option value="this month">This month</option>
          <option value="last month">Last month</option>
        </select>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mt-0 mb-0 header-title">Revenue Generated</h5>
            <div class="text-muted mt-1">Last 30 days</div>
            <div class="media">
              <div class="media-body">
                <h2 v-if="reports && !loadingReport" class="mb-0 mt-4"
                  >{{ reports.total_revenue_30 | currency }}</h2
                >
                <p v-if="reports && !loadingReport" class="mt-2">
                  Average daily revenue is
                  <strong class="text-success"
                    >up {{ reports.revenue_week }}%</strong
                  >
                  over last week and
                  <strong class="text-success"
                    >up {{ reports.revenue_month }}%</strong
                  >
                  over last month.
                </p>
                <div v-else>
                  <b-spinner />
                </div>

                <div class="row mt-3">
                  <div class="col-4">
                    <h5 v-if="reports && !loadingReport" class="mb-0"
                      >{{ reports.total_revenue | currency }}</h5
                    >
                    <b-spinner v-else />
                    <span class="text-muted font-size-12">All-Time</span>
                  </div>
                  <div class="col-4">
                    <h5 v-if="reports && !loadingReport" class="mb-0"
                      >{{ reports.total_revenue_14 | currency }}</h5
                    >
                    <b-spinner v-else />
                    <span class="text-muted font-size-12">Last 14 days</span>
                  </div>
                  <div class="col-4">
                    <h5 v-if="reports && !loadingReport" class="mb-0"
                      >{{ reports.total_revenue_7 | currency }}</h5
                    >
                    <b-spinner v-else />
                    <span class="text-muted font-size-12">Last 7 days</span>
                  </div>
                </div>
              </div>
            </div>
            <apexcharts
              v-if="reports && !loadingReport"
              type="bar"
              height="296"
              :series="revenueGeneratedChart"
              :options="statChart.chartOptions"
            ></apexcharts>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mt-0 mb-0 header-title">New Subscribers</h5>
            <div class="text-muted mt-1">Last 30 days</div>
            <div class="media">
              <div class="media-body">
                <h2 v-if="reports && !loadingReport" class="mb-0 mt-4">{{
                  reports.total_subscribers_30
                }}</h2>
                <p v-if="reports && !loadingReport" class="mt-2">
                  Average daily subscriber is
                  <strong class="text-success"
                    >up {{ reports.subscriber_week }}%</strong
                  >
                  over last week and
                  <strong class="text-success"
                    >up {{ reports.subscriber_month }}%</strong
                  >
                  over last month.
                </p>
                <div v-else>
                  <b-spinner />
                </div>
                <div class="row mt-3">
                  <div class="col-4">
                    <h5 v-if="reports && !loadingReport" class="mb-0">{{
                      reports.total_subscribers | number
                    }}</h5>
                    <b-spinner v-else />
                    <span class="text-muted font-size-12">All-Time</span>
                  </div>
                  <div class="col-4">
                    <h5 v-if="reports && !loadingReport" class="mb-0">{{
                      reports.total_subscribers_14 | number
                    }}</h5>
                    <b-spinner v-else />
                    <span class="text-muted font-size-12">Last 14 days</span>
                  </div>
                  <div class="col-4">
                    <h5 v-if="reports && !loadingReport" class="mb-0">{{
                      reports.total_subscribers_7 | number
                    }}</h5>
                    <b-spinner v-else />
                    <span class="text-muted font-size-12">Last 7 days</span>
                  </div>
                </div>
              </div>
            </div>

            <apexcharts
              v-if="reports && !loadingReport"
              type="bar"
              height="296"
              :series="newSubscribersChart"
              :options="statChart.chartOptions"
            ></apexcharts>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body p-0">
            <div class="media p-3">
              <div class="media-body">
                <span class="text-muted text-uppercase font-size-12 font-weight-bold">Offers Requested</span>
                <h2 v-if="reports && !loadingReport" class="mb-0">{{ reports.offers_requested }}</h2>
                <div v-else class="text-center">
                  <b-spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body p-0">
            <div class="media p-3">
              <div class="media-body">
                <span class="text-muted text-uppercase font-size-12 font-weight-bold">% Redemption</span>
                
                <h2 v-if="reports && !loadingReport" class="mb-0">{{ reports.percent_redemption }}</h2>
                <div v-else class="text-center">
                  <b-spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mt-0 mb-0 header-title">Top 10 Customers</h5>

            <div class="table-responsive mt-4">
              <table
                v-if="reports && !loadingReport"
                class="table table-hover table-nowrap mb-0"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Phone Number</th>
                    <th>Name</th>
                    <th>Total amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(customer, index) in reports.customers"
                    :key="index"
                  >
                    <td>#{{ index + 1 }}</td>
                    <td>{{ customer.phone }}</td>
                    <td>{{ customer.first_name }} {{ customer.last_name }}</td>
                    <td>{{ customer.total_amount | currency }}</td>
                    <td>
                      <b-dropdown
                        variant="black"
                        toggle-class="arrow-none text-muted pr-0"
                      >
                        <template v-slot:button-content>
                          <i class="uil uil-ellipsis-v"></i>
                        </template>
                        <b-dropdown-item
                          :to="{
                            name: 'business.contacts.edit',
                            params: { id: customer.id },
                          }"
                        >
                          <i class="uil uil-edit mr-2"></i>Edit
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-center">
                <b-spinner />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <apexchart
              v-if="reports && !loadingReport"
              type="line"
              height="296"
              :series="revenueAreaChart.series"
              :options="revenueAreaChart.chartOptions"
            ></apexchart>
            <div v-else class="text-center">
              <b-spinner />
            </div>
          </div>
        </div>
        
      </div> -->
    </div>
    <b-modal v-model="showPrefixModal" title="Business Prefix" hide-footer>
      <div v-if="!prefixSuccess">
        <p class="text-center">
          Unlike email, text messages don't have a built in way to identify who sends messages. A prefix will be added to the beginning of your text messages to clarify your identity. Please input your business name below. You can update this at anytime by navigating to Settings and then SMS Registration. 
        </p>
        <div class="row">
          <div class="col-md-12">
            <TextInput v-model="businessPrefix" label="Business Prefix" />
          </div>
          <div class="col-md-12 text-center mt-4">
            <b-button variant="primary" :disabled="!businessPrefix" @click="updatePrefix">
              <b-spinner v-if="processing" small />
              <span v-else>
                <i class="uil uil-cog"></i> Update
              </span>
            </b-button>
          </div>
        </div>
      </div>
      <div v-else class="d-block">
        <p class="text-center">
          Thanks for submitting your information. Your business prefix has been updated. You can update this at anytime by navigating to Settings and then SMS Registration
        </p>
        <div class="text-center mt-4">
          <b-button variant="primary" @click="showPrefixModal = false">
            <i class="uil uil-check"></i> Got It
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexcharts: VueApexCharts,
  },

  data() {
    return {
      showPrefixModal: false,
      businessPrefix: '',
      processing: false,
      prefixSuccess: false,
      reports: null,
      loadingReport: false,
      selectedDates: [
        moment()
          .subtract(30, 'days')
          .format(),
        moment().format(),
      ],
      revenueAreaChart: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            theme: 'dark',
            x: { show: false },
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#43d39e', '#bb0303'],
          xaxis: {
            type: 'string',
            categories: [],
            tooltip: {
              enabled: false,
            },
            axisBorder: {
              show: false,
            },
          },
        },
        series: [],
      },
      statChart: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            theme: 'dark',
            x: { show: false },
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#43d39e'],
          xaxis: {
            type: 'string',
            categories: [],
            tooltip: {
              enabled: false,
            },
            axisBorder: {
              show: false,
            },
          },
        },
        series: [],
      },
      revenueGeneratedChart: [],
      newSubscribersChart: [],
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    business() {
      return this.$store.getters['auth/business']
    },
    hasBusinessPrefix() {
      return this.business && this.business.organizational_prefix
    }
  },

  mounted() {
    this.getReport(this.selectedDates)
    if (!this.hasBusinessPrefix) {
      this.showPrefixModal = true
    }
  },

  methods: {
    onSubmitPrefix() {
      this.showPrefixModal = false
    },

    updatePrefix() {
      this.processing = true

      let params = {
        organizational_prefix: this.businessPrefix,
      }
      this.$store
        .dispatch('business/updatePrefix', params)
        .then((data) => {
          this.processing = false
          this.prefixSuccess = true
        })
    },

    getReport(dateRange) {
      this.loadingReport = true
      this.reports = null

      this.$store
        .dispatch('report/dashboard', dateRange)
        .then((reports) => {
          this.reports = reports

          this.revenueAreaChart.chartOptions.xaxis.categories = reports.labels
          this.revenueAreaChart.series = reports.series

          this.statChart.chartOptions.xaxis.categories = reports.labels
          this.revenueGeneratedChart = reports.revenue_series
          this.newSubscribersChart = reports.subscriber_series

          this.loadingReport = false
        })
        .catch(() => {
          this.loadingReport = false
        })
    },

    selectRange(event) {
      switch (event.target.value) {
        case 'last 3 days':
          this.selectedDates = [
            moment().format(),
            moment()
              .subtract(3, 'days')
              .format(),
          ]
          break
        case 'last 7 days':
          this.selectedDates = [
            moment().format(),
            moment()
              .subtract(7, 'days')
              .format(),
          ]
          break
        case 'this week':
          this.selectedDates = [
            moment()
              .startOf('isoWeek')
              .format(),
            moment()
              .endOf('isoWeek')
              .format(),
          ]
          break
        case 'last week':
          this.selectedDates = [
            moment()
              .subtract(1, 'weeks')
              .startOf('isoWeek')
              .format(),
            moment()
              .subtract(1, 'weeks')
              .endOf('isoWeek')
              .format(),
          ]
          break
        case 'this month':
          this.selectedDates = [
            moment()
              .startOf('month')
              .format(),
            moment().format(),
          ]
          break
        case 'last month':
          this.selectedDates = [
            moment()
              .subtract(1, 'months')
              .startOf('month')
              .format(),
            moment()
              .subtract(1, 'months')
              .endOf('month')
              .format(),
          ]
          break
      }
    },

    handleDateChanged(selectedDates) {
      if (selectedDates.length === 2) {
        this.getReport(selectedDates)
      }
    },
  },
}
</script>
